import React from "react";
import Navbar from "./navbar";
import ToggleMenu from "./toggleMenu";
import TopNavigation from "./topNavigation";
import Whatsapp from "./whatsapp";



const Header = () => {
    return (
        <>
            <TopNavigation />
            <div className="header_absolute header_layout_1">
                <Navbar />
                <ToggleMenu />
                <Whatsapp />
            </div>
        </>
    )
}
export default Header;


