import React from 'react'
import Bot from '../images/fibrabot.png';

function whatsapp() {
  return (
    <div className="whatsapp">
      <a href="https://wa.me/556935352424">
        <img src={Bot} className="bot" alt="Atendimento pelo whatsapp" />
        <span class="wpp-button btn-green">
          <i className="fa fa-whatsapp pr-2 text-white" />
          <span className="small-text text-white">Atendimento online</span>
        </span>
      </a>
    </div>
  )
}

export default whatsapp
