import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import foto01 from "../img/01.jpg";
import foto02 from "../img/02.jpg"
import foto03 from "../img/03.jpg"
import foto04 from "../img/04.jpg"
import foto05 from "../img/05.jpg"
import foto06 from "../img/06.jpg"
import foto07 from "../img/07.jpg"
import foto08 from "../img/08.jpg"
import foto09 from "../img/09.jpg"
import foto10 from "../img/10.jpg"
import { Helmet } from "react-helmet";

class Contato extends React.Component {
  state = {
    message: '',
  }

  SubmitForm(e) {
    e.preventDefault();
    const message = encodeURIComponent(this.state.message);
    window.open(`https://api.whatsapp.com/send?phone=556935352424&text=${message}`);
    this.setState({ message: '' });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Contato - Fibraconn</title>
        </Helmet>
        <div className="preloader">
          <div className="preloader_image" />
        </div>
        {/* search modal */}

        {/* wrappers for visual page editor and boxed version of template */}
        <div id="canvas">
          <div id="box_wrapper">
            {/* template sections */}
            {/*topline section visible only on small screens|*/}

            {/*eof topline*/}
            <Header />
            <section className="page_title cs s-pt-60 s-pb-10 s-pt-lg-130 s-pb-lg-60 page_title text-center">
              <div className="container-fluid">
                <div className="row">
                  <div className="divider-60" />
                  <div className="col-md-12 text-center">
                    <h1>Fale conosco</h1>
                  </div>
                  <div className="divider-50" />
                </div>
              </div>
              <ol className="breadcrumb full-width">
                <li className="breadcrumb-item">
                  <a href="./">INÍCIO</a>
                </li>
                <li className="breadcrumb-item active">
                  FALE CONOSCO
                  </li>
              </ol>
            </section>
          </div>
          <section className="ls s-py-80 s-py-lg-120 s-py-xl-160 c-gutter-100 booking-form container-px-30">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 animate" data-animation="scaleAppear">
                  <h5 className="text-center text-md-left special-heading">
                    DÚVIDAS? NÓS LIGAMOS PARA VOCÊ!<br />
                    <span className="text-uppercase">Assine agora a melhor Internet</span>
                  </h5>
                  <div className="divider-50 d-none d-xl-block" />
                  <div>
                    <form onSubmit={(e) => this.SubmitForm(e)} className="contact-form c-mb-10 c-mb-md-20 c-gutter-20 text-center text-md-left">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group has-placeholder">
                            <label htmlFor="message">Mensagem</label>
                            <i className="fa fa-edit color-main" />
                            <textarea aria-required="true"
                              rows={6} cols={45}
                              name="message" id="message"
                              className="form-control bg-light"
                              placeholder="Escreva aqui sua mensagem e logo entraremos em contato"
                              value={this.state.message}
                              onChange={(e) => this.setState({ message: e.target.value })} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 text-center text-md-left mt-30">
                          <div className="form-group">
                            <button type="button"
                              id="contact_form_submit" name="contact_submit"
                              className={this.state.checked ? "btn btn-green" : "btn btn-green disabled"}
                              onClick={(e) => this.SubmitForm(e)}><p>ENVIAR</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/*.col-* */}
              </div>
            </div>
          </section>
          <section className="footer-carousel c-gutter-0 container-px-0 s-py-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <div className="owl-carousel small-gallery-carousel" data-margin={0} data-responsive-lg={8} data-responsive-md={4} data-responsive-sm={3} data-responsive-xs={2} data-nav="true" data-loop="true">
                    <img src={foto01} alt="foto ilustrativa" />
                    <img src={foto02} alt="" />
                    <img src={foto03} alt="" />
                    <img src={foto04} alt="" />
                    <img src={foto05} alt="" />
                    <img src={foto06} alt="" />
                    <img src={foto07} alt="" />
                    <img src={foto08} alt="" />
                    <img src={foto09} alt="" />
                    <img src={foto10} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Contato;