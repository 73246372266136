import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="page_footer ls ms s-pt-40 s-pb-70 s-py-lg-120 s-pt-xl-67 s-pb-xl-105 c-gutter-60 text-center text-md-left">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xl-3 order-xl-1 order-md-1 animate animated fadeInUp" data-animation="fadeInUp">
                <div className="widget widget_text">
                  <h4 className="widget-title">Sobre-nós</h4>
                  <p>
                    Bem-vindo ao maior provedor de serviços de banda larga e Internet onde você pode
                    escolher o melhor plano de todos os tempos.
                    </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 order-xl-2 order-md-4 animate animated fadeInUp" data-animation="fadeInUp">
                <div className="widget links">
                  <h4 className="widget-title">Links</h4>
                  <ul>
                    <li className="cat-item">
                      <a href="/faq">Dúvidas frequentes</a>
                    </li>
                    <li className="cat-item">
                      <a href="/contato">Fale Conosco</a>
                    </li>
                    <li className="cat-item">
                      <a href="/contratos/">Contratos e Termos</a>
                    </li>
                    <li className="cat-item">
                      <a target="blank" href="https://fibraconn.com.br/central_assinante_web/login">Central
                          do Cliente</a>
                    </li>
                    <li className="cat-item">
                      <a target="top-nav" href="https://api.whatsapp.com/send?phone=556935352424&text=Olá, vim pelo site e preciso de ajuda!">Suporte</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 order-xl-3 order-md-3 animate animated fadeInUp" data-animation="fadeInUp">
                <div className="widget services-links">
                  <h4 className="widget-title">Serviços</h4>
                  <ul>
                    <li className="cat-item">
                      <a href="https://api.whatsapp.com/send?phone=556935352424&text=Ol%C3%A1,%20vim%20pelo%20site%20e%gostaria de conhecer todos os planos!">Conheça todos nossos planos!</a>
                    </li>
                    <li className="cat-item">
                      <a target="blank" href="http://ixcsoft.speedtestcustom.com/">Teste de
                          velocidade</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 order-xl-4 order-md-2 animate animated fadeInUp" data-animation="fadeInUp">
                <div className="widget widget_icons_list">
                  <h4 id="contact" className="widget-title">Entre em contato!</h4>
                  <div className="row">
                    <div className="col-auto">
                      <a target="blank" href="tel:(69) 99281-9676">
                        <i className="color-main7 fa fa-phone d-inline-flex" />
                          (69) 99281-9676
                        </a>
                        (Ariquemes)
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <a target="blank" href="tel:(69) 3535-2424">
                        <i className="color-main7 fa fa-phone d-inline-flex" />
                          (69) 3535-2424
                        </a>
                        (Ariquemes)
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <a target="blank" href="tel:(69) 99200-2525">
                        <i className="color-main7 fa fa-phone d-inline-flex" />
                          (69) 99200-2525
                        </a>
                        (Alto Paraíso)
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <a target="blank" href="tel:(69) 3521-2988">
                        <i className="color-main7 fa fa-phone d-inline-flex" />
                          (69) 3521-2988
                        </a>
                        (Jarú)
                      </div>
                  </div>
                  <div className="row pt-1">
                    <div className="col-auto">
                      <a href="mailto:financeiro@fibraconn.com.br"> <i className="color-main7 fa fa-envelope-o d-inline-flex" />
                          financeiro@fibraconn.com.br
                        </a>
                    </div>
                  </div>
                  <h4 className="widget-title">Faça uma visita!</h4>
                  <p>R. Suécia, 3309<br />Jardim Europa, Ariquemes - RO<br />76871-304</p>
                  <a href="https://www.google.com.br/maps/place/Fibraconn+-+Tecnologia+Avan%C3%A7ada/@-9.8906356,-63.0376249,17z/data=!3m1!4b1!4m5!3m4!1s0x93cc97749ab8a173:0x37c81b20afeb96d3!8m2!3d-9.8906356!4d-63.0354308" className="small-text" target="blank">
                    <i className="fa fa-map-marker color-main3 fs-14" />
                      Ver no mapa
                    </a>
                  <p>R. Rondônia, 3552<br />Centro, Alto Paraíso - RO<br />76862-000</p>
                  <a href="https://www.google.com/maps/place/Fibraconn+Tecnologia+Avançada/@-9.7197924,-63.3176203,15z/data=!4m6!3m5!1s0x93ccc5e8938b1385:0x87e9186ea7483d84!8m2!3d-9.7193715!4d-63.3175478!16s%2Fg%2F11kbwnmqkg?hl=pt" className="small-text" target="blank">
                    <i className="fa fa-map-marker color-main3 fs-14" />
                      Ver no mapa
                    </a>
                  <p>R. Paraná, 3165<br />Setor 01, Jarú - RO<br />76890-000</p>
                  <a href="https://goo.gl/maps/n3Sz8Mj9WejUGgqbA" className="small-text" target="blank">
                    <i className="fa fa-map-marker color-main3 fs-14" />
                      Ver no mapa
                    </a>
                </div>
                <div className="divider-45 d-none d-md-block d-xlz-none" />
              </div>
            </div>
          </div>
        </footer>
        <section className="page_copyright ds s-py-10 copyright-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="divider-15 d-none d-lg-block" />
              <div className="col-md-12 text-center">
                <p> Copyright ©<span className="copyright_year">2020</span> Todos os direitos FIBRACONN</p>
              </div>
              <div className="divider-15 d-none d-lg-block" />
            </div>
          </div>
        </section>
      </>
    )
  }

}
export default Footer;