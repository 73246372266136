import React, { useState } from "react";

const Ariquemes = 'https://www.google.com/maps/d/embed?mid=1eQCIgS9Erz9sd9oVuYtpqdJqe6_MeBlc';
const AltoParaiso = 'https://www.google.com/maps/d/embed?mid=18jAbIT_bRc1Q3JhqxF1Wxf827_BzwcOG&ll=-9.717715071263868%2C-63.31824064598334&z=14';
const Jaru = 'https://www.google.com/maps/d/embed?mid=12Fq4LlbYzaQExtv6bBzM2LHzvhSddtc&ehbc=2E312F';

function Mapa() {

  const [showOverlay, setShowOverlay] = useState(true);
  const [cidade, setCidade] = useState(Ariquemes);

  return (
    <section className="ls ms map-section s-py-40 s-py-lg-100" id="location">
      <div className="page1_map">
        <div className="page1_map_hide">
          <div className="btn-group" role="group">
            <button
              onClick={() => setCidade(Ariquemes)}
              type="button" className={`btn btn-maincolor btn-sm ${cidade !== Ariquemes ? 'disabled' : ''}`}>Ariquemes</button>
            <button
              onClick={() => setCidade(AltoParaiso)}
              type="button" className={`btn btn-maincolor btn-sm ${cidade !== AltoParaiso ? 'disabled' : ''}`}>Alto paraíso</button>
            <button
              onClick={() => setCidade(Jaru)}
              type="button" className={`btn btn-maincolor btn-sm ${cidade !== Jaru ? 'disabled' : ''}`}>Jarú</button>
          </div>
        </div>
        {showOverlay && (
          <div
            className="map-over"
            onClick={() => {
              setShowOverlay(false);
            }}
          />
        )}
        <iframe
          title="Mapa cobertura"
          src={cidade}
          style={{ width: "100%" }}
          height={500}
        />
      </div>
      <div className="container">
        <div className="divider-10" />
        <div className="row text-center text-md-left">
          <div id="name_Map" className="col-12 col-lg-4 p-0">
            <h5 className="special-heading text-center text-lg-left">
              Nossa cobertura em
            </h5>
            <h6 className="text-center text-lg-left text-uppercase">
              Ariquemes, Alto Paraíso e Jarú
            </h6>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p>
              <i
                className="color-main4 fa fa-map-marker fs-14"
                aria-hidden="true"
              />
              Áreas com nossas cobertura
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p>
              <i className="color-main2 fa fa-wifi fs-14" aria-hidden="true" />
              Localização da Fibraconn
            </p>
          </div>
        </div>
        <div className="divider-10" />
      </div>
    </section >
  );
}

export default Mapa;
