import React from "react";
const ToggleMenu = () => {
  return (
    <div className="page_header_wrapper main-header-side">
      <div className="page_header_side header_slide header_side_right ls">
        <span className="toggle_menu toggle_menu_side header-slide"><span /></span>
        <div className="scrollbar-macosx">
          <div className="side_header_inner">
            <div className="header-side-menu">
              {/* main side nav start */}
              <nav className="mainmenu_side_wrapper">
                <ul className="nav menu-click">
                  <li>
                    <a className="d-flex row" href="/faq">
                      <div className="col-auto pr-0">
                        <i className="color-main7 fa fa-question-circle d-inline-flex" />
                      </div>
                      <div className="col">
                        <span>
                          dúvidas frequentes
                              </span>
                      </div>
                    </a>
                  </li>
                  <li className="pt-1">
                    <a className="d-flex row" href="/contato">
                      <div className="col-auto pr-0">
                        <i className="color-main7 fa fa-paper-plane d-inline-flex" />
                      </div>
                      <div className="col">
                        <span>
                          fale conosco
                              </span>
                      </div>
                    </a>
                  </li>
                  <li className="pt-1">
                    <a target="blank" className="d-flex row" href="https://fibraconn.com.br/central_assinante_web/login">
                      <div className="col-auto pr-0">
                        <i className="color-main7 fa fa-user d-inline-flex" />
                      </div>
                      <div className="col">
                        <span>
                          central do assinante
                              </span>
                      </div>
                      
                    </a>
                  </li>
                </ul>
              </nav>
              {/* eof main side nav */}
            </div>
          </div>
        </div>
        <div className="facebook-messeger d-flex align-items-center hero-bg">
          <i className="fa fa-whatsapp fs-40 pr-2 text-success" />
          <a href="https://wa.me/556935352424" className="small-text">
            chama no<br />whatsapp
                </a>
        </div>
      </div>
    </div>
  )
}

export default ToggleMenu;