import React from "react";
import Logo from "../img/logo.png"
import Logopreta from "../img/logo_preto.png"

export default class Navbar extends React.Component {
  state = {
    image: Logo,
    showNav: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.MudaImage.bind(this));
  };

  MudaImage() {
    let height = document.documentElement.scrollTop;
    if (height >= 84)
      this.setState({ image: Logopreta, showNav: true });
    else
      this.setState({ image: Logo, showNav: false });
  }


  render() {
    return (
      <header className="page_header ls justify-nav-end">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-4 col-md-5 col-9">
              <a href="/" className="logo">
                <img className="img-fluid" src={this.state.image} alt="imagem ilustrativa" />
              </a>
            </div>
            <div className={`col-xl-7 col-lg-8 col-md-7 col-1 ${this.state.showNav ? 'visible' : 'invisible'}`}>
              <div className="nav-wrap">
                {/* main nav start */}
                <nav className="top-nav">
                  <ul className="nav sf-menu">
                    <li>
                      <a href="/">Início</a>
                    </li>
                    <li>
                      <a href="/#price">planos</a>
                    </li>
                    <li>
                      <a href="/#about">Quem somos</a>
                    </li>
                    <li>
                      <a href="/contratos/">Contratos e Termos</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* header toggler */}
        <span className="toggle_menu"><span /></span>
      </header>
    )
  }
}