import React from 'react';
import { Redirect } from 'react-router-dom';
import api from '../controllers/Api';
import Footer from '../components/footer';
import Header from '../components/header';
import { Helmet } from 'react-helmet';

class FAQ extends React.Component{
  state = {
    tipo : "Duvida",
    name : '',
    email : '',
    phoneNumber : '',
    assunto : '',
    message : '',
    redirect : false,
  }

  SumitForm(e){
    e.preventDefault();
    api
    .post('/sendfaq',{
      tipo : this.state.tipo,
      name : this.state.name,
      email : this.state.email,
      phoneNumber : this.state.phoneNumber,
      assunto : this.state.assunto,
      message : this.state.message,
      }).then(
      this.setState({ redirect : true}),
        )
  }


    render() {
      const {redirect } = this.state
        return (
            <div>
              <Helmet>
                <title>FAQ - Fibraconn</title>
              </Helmet>
          <div className="preloader">
            <div className="preloader_image" />
          </div>
          <div id="canvas">
            <div id="box_wrapper">
            <Header/>
            <section className="page_title cs s-pt-60 s-pb-10 s-pt-lg-130 s-pb-lg-60 page_title text-center">
                <div className="container-fluid">
                  <div className="row">
                    <div className="divider-60" />
                    <div className="col-md-12 text-center">
                      <h1>DÚVIDAS FREQUENTES</h1>
                    </div>
                    <div className="divider-50" />
                  </div>
                </div>
                <ol className="breadcrumb full-width">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/faq" >Duvidas frequentes</a>
                  </li>
                </ol>
              </section>
            <section className="s-pb-70 s-pt-40 s-pt-lg-60 s-pt-xl-80 s-pb-xl-70 faq-page">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content ls">
                      <div id="accordion01" role="tablist">
                        <div className="tab-block">
                          <div id="collapse01_header">
                            <p className="text-normal">
                              <a data-toggle="collapse" href="#collapse01" aria-expanded="true" aria-controls="collapse01">
                                Como proceder em casos de problema com a conexão?
                              </a>
                            </p>
                          </div>
                          <div id="collapse01" className="collapse show" aria-labelledby="collapse01_header" data-parent="#accordion01">
                            <div className="card-body">
                              <p>
                                Oferecemos Suporte Técnico Online gratuito, disponível todos os dias da semana das 8h às 17h, sábados das 8h as 12h. 
                                Não atendemos aos domingos e feriados.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-block">
                          <div role="tab" id="collapse03_header">
                            <p className="text-normal">
                              <a className="collapsed" data-toggle="collapse" href="#collapse03" aria-expanded="false" aria-controls="collapse03">
                                Quais as formas de pagamento de mensalidades?
                              </a>
                            </p>
                          </div>
                          <div id="collapse03" className="collapse" role="tabpanel" aria-labelledby="collapse03_header" data-parent="#accordion01">
                            <div className="card-body">
                              <p>
                                Os pagamentos podem ser feitos através de boleto bancário, cartão de crédito e débito em conta.													</p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-block">
                          <div role="tab" id="collapse04_header">
                            <p className="text-normal">
                              <a className="collapsed" data-toggle="collapse" href="#collapse04" aria-expanded="false" aria-controls="collapse04">
                                O tempo pode prejudicar o sinal da internet via rádio, como em dias de chuva, por exemplo?
                              </a>
                            </p>
                          </div>
                          <div id="collapse04" className="collapse" role="tabpanel" aria-labelledby="collapse04_header" data-parent="#accordion01">
                            <div className="card-body">
                              <p>
                                As tempestades geralmente provocam prejuízos, como quedas de árvores e interrupção de energia elétrica. Da mesma forma, o mau tempo também pode danificar equipamentos de transmissão do sinal de internet localizados em regiões vulneráveis às condições climáticas.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-block">
                          <div role="tab" id="collapse05_header">
                            <p className="text-normal">
                              <a className="collapsed" data-toggle="collapse" href="#collapse05" aria-expanded="false" aria-controls="collapse05">
                                Minha internet vai ter velocidade reduzida quando atingir o limite do pacote/plano?	</a>
                            </p>
                          </div>
                          <div id="collapse05" className="collapse" role="tabpanel" aria-labelledby="collapse05_header" data-parent="#accordion01">
                            <div className="card-body">
                              <p>
                                Não. Nossos serviços não funcionam como um pacote de dados. Oferecemos uma conexão estável e com uso ilimitado, onde a velocidade vai ser constante 24 horas por dia, 7 dias por semana e sem limite de download/upload.													</p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-block">
                          <div role="tab" id="collapse06_header">
                            <p className="text-normal">
                              <a className="collapsed" data-toggle="collapse" href="#collapse06" aria-expanded="false" aria-controls="collapse06">
                                O que posso fazer se minha internet não está funcionando?													</a>
                            </p>
                          </div>
                          <div id="collapse06" className="collapse" role="tabpanel" aria-labelledby="collapse06_header" data-parent="#accordion01">
                            <div className="card-body">
                              <p>
                                Se ocorrer lentidão, ou alguma outra dificuldade relacionada à conexão, desligue o equipamento da energia elétrica por cerca de 2 minutos. Se o problema persistir, entre em contato com o suporte técnico. Estamos disponíveis com profissionais capacitados para auxiliá-lo. Mantenha suas faturas sempre em dia.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-block">
                          <div role="tab" id="collapse07_header">
                            <p className="text-normal">
                              <a className="collapsed" data-toggle="collapse" href="#collapse07" aria-expanded="false" aria-controls="collapse07">
                                Existe fibra óptica em todas as ruas?													</a>
                            </p>
                          </div>
                          <div id="collapse07" className="collapse" role="tabpanel" aria-labelledby="collapse07_header" data-parent="#accordion01">
                            <div className="card-body">
                              <p>
                                Não. Porém, nossa rede de fibra óptica está em plena expansão. Caso tenha interesse neste serviço, preencha o formulário de contato e aguarde o contato da nossa equipe comercial.													</p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-block">
                          <div role="tab" id="collapse08_header">
                            <p className="text-normal">
                              <a className="collapsed" data-toggle="collapse" href="#collapse08" aria-expanded="false" aria-controls="collapse08">
                                Não recebi a fatura desse mês, como faço para pagá-la?													</a>
                            </p>
                          </div>
                          <div id="collapse08" className="collapse" role="tabpanel" aria-labelledby="collapse08_header" data-parent="#accordion01">
                            <div className="card-body">
                              <p>
                                A 2ª via da fatura está disponível na Área do Cliente. Voce? tambe?m pode solicitar por e-mail, pelas redes sociais ou retira?-la na unidade da sua regia?o. Não esqueça de manter seu cadastro sempre atualizado.													</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div></div></div></div></section>
            <section className="s-pb-80 s-pb-lg-120 s-pb-xl-160 faq-form">
              <div className="container">
                <div className="form-header color-darkgrey">
                  <h4>
                    Faça uma pergunta!
                  </h4>
                </div>
                <div>
                <form onSubmit={(e) => this.SumitForm(e) } className="contact-form c-mb-20 c-gutter-20">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group has-placeholder">
                        <label htmlFor="name">Nome completo<span className="required">*</span></label>
                        <i className="fa fa-user color-main" />
                        <input type="text" 
                        aria-required="true" 
                        size={30} 
                        name="name" id="name" 
                        className="form-control" 
                        placeholder="Nome completo" 
                        onChange={(e) => this.setState({name : e.target.value})}/>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group has-placeholder">
                        <label htmlFor="email">E-mail<span className="required">*</span></label>
                        <i className="fa fa-envelope color-main" />
                        <input type="email" 
                        aria-required="true" 
                        size={30}  
                        name="email" id="email" 
                        className="form-control" 
                        placeholder="E-mail" 
                        onChange={(e) => this.setState({email: e.target.value})}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group has-placeholder">
                        <label htmlFor="phone">Telefone para contato<span className="required">*</span></label>
                        <i className="fa fa-phone color-main" />
                        <input type="text" 
                        aria-required="true" 
                        size={30}  
                        name="phone" id="phone" 
                        className="form-control" 
                        placeholder="Telefone para contato" 
                        onChange={(e) => this.setState({phoneNumber: e.target.value})}/>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group has-placeholder">
                        <label htmlFor="topic">Assunto<span className="required">*</span></label>
                        <i className="fa fa-paperclip color-main" />
                        <input type="text" 
                        aria-required="true" 
                        size={30}  
                        name="topic" 
                        id="topic" 
                        className="form-control" 
                        placeholder="Assunto" 
                        onChange={(e) => this.setState({assunto : e.target.value})}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group has-placeholder">
                        <label htmlFor="message">Mensagem</label>
                        <i className="fa fa-edit color-main" />
                        <textarea aria-required="true" 
                        rows={6} cols={45} 
                        name="message" id="message" 
                        className="form-control" 
                        placeholder="Mensagem" 
                        onChange={(e) => this.setState({message: e.target.value})}/>
                      </div>
                    </div>
                  </div>
                  <div className="row c-mt-10 c-mt-lg-40">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <button  onClick={(e) => this.SumitForm(e)} type="submit" id="contact_form_submit" name="contact_submit" className="btn btn-maincolor"><span>PERGUNTAR</span></button>
                      </div>
                    </div>
                  </div>
                </form>
                {redirect && (<Redirect to={"/"}/>)}
                </div>
              </div>
            </section>
           </div>
           <Footer/>
        </div>
        </div>
        )
    }
}
export default FAQ;