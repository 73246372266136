import React from 'react';
import ReactDOM from 'react-dom';
import Inicio from './pages/Inicio';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import FAQ from './pages/FAQ';
import Contato from './pages/Contato';
import './css/bootstrap.min.css'
import './css/animations.css'
import './css/font-awesome.css'
import './css/main.css'
import "./css/map.css"
import "./css/color.css";
import "bootstrap/dist/js/bootstrap.bundle";

ReactDOM.render(
  <BrowserRouter basename={"/"}>
    <Switch>
      <Route path={`/`} exact={true} component={Inicio} />
      <Route path={`/FAQ`} component={FAQ} />
      <Route path={`/contato`} component={Contato} />
    </Switch>
  </BrowserRouter>
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
