import React from "react";


export default class TopNavigation extends React.Component {
  render() {
    return (
      <section className="page_topline ls s-borderbottom c-my-20">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col">
              <ul className="sf-menu sf-menu-clearpadding">
                <li>
                  <a className="small-text" href="/">Início</a>
                </li>
                <li>
                  <a className="small-text" href="/#price">planos</a>
                </li>
                <li>
                  <a className="small-text" href="/#about">Quem somos</a>
                </li>
                    <li>
                      <a href="/contratos/">Contratos e Termos</a>
                    </li>
              </ul>
            </div>
            <div className="col-12 col-xl-auto text-center mr-xl-150">
              <a className="btn-green small-text btn-central"
                href="https://fibraconn.com.br/central_assinante_web/login">
                <i className="fa fa-user mr-2" /> CENTRAL DO CLIENTE
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}