import React, { useCallback } from 'react';
import Footer from '../components/footer';
import Slide0 from "../images/movie_time1.jpg"
import Slide1 from "../images/slide1-tech.jpg"
import Slide2 from "../images/slide2-city.jpg"
import Slide3 from "../images/slide3-server.jpg"
import Bottom from "../images/bottom.png.webp"
import Gauge7 from "../images/gauge7.png"
import Gauge6 from "../images/gauge6.png"
import Gauge5 from "../images/gauge5.png"
import Gauge4 from "../images/gauge4.png"
import Gauge3 from "../images/gauge3.png"
import Gauge1 from "../images/gauge1.png"
import mocaPulando from "../images/mocaPulando.png.webp"
import Missao from "../images/slide-missao.jpg"
import Valores from "../images/slide-valores.jpg"
import Visao from "../images/slide-visao.jpg"
import Security from "../images/security.png.webp"
import Rocket from "../images/foguete.png.webp"
import Network from "../images/local-network.png.webp"
import Information from "../images/information.png.webp"
import WhatsApp from "../images/whatsapp.png.webp"
import ParaSempre from "../images/para-sempre.png.webp"
import Particles from 'react-particles-js';
import configParticles from '../utils/particles.config.json';
import Mapa from '../components/Inicio/Mapa';
import Watch from '../components/Inicio/Watch';
import { Helmet } from "react-helmet";
import Header from '../components/header';
import { useResizeDetector } from 'react-resize-detector';

const Inicio = () => {
  const onResize = useCallback(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  useResizeDetector({ onResize });

  return (
    <div className="App">
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fibraconn - Tecnologia Avançada! </title>
        </Helmet>
        <div id="canvas">
          <div id="box_wrapper">
            <Header />
            <section className="page_slider">
              <Particles className="particles-js" params={configParticles} width="100%" height="100%" />
              <div className="flexslider" data-nav="false">
                <ul className="slides">
                  <li className="ds slide0">
                    <img loading="lazy" src={Slide0} alt="imagem ilustrativa" />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="intro_layers_wrapper">
                            <div className="intro_layers">
                              <div className="intro_layer" data-animation="fadeInRight">
                                <h1 className="slide2 intro_featured_word">
                                  Sessão<br />com<br />Pipoca</h1>
                                <h5 className="intro-absolute fw-300 text-uppercase intro_before_featured_word">
                                  Fibraconn<br /> + Watch
                                </h5>
                              </div>
                              <div className="intro_layer" data-animation="fadeInUp">
                                <p className="text-uppercase intro_after_featured_word">Assine nossos
                                  planos e aproveite todo nosso catálogo de filmes e séries!</p>
                              </div>
                            </div> {/* eof .intro_layers */}
                          </div> {/* eof .intro_layers_wrapper */}
                        </div> {/* eof .col-* */}
                      </div>{/* eof .row */}
                    </div>{/* eof .container */}
                  </li>
                  <li className="ds">
                    <img loading="lazy" src={Slide1} alt="imagem ilustrativa" />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="intro_layers_wrapper">
                            <div className="intro_layers">
                              <div className="intro_layer" data-animation="fadeInRight">
                                <h1 className="intro_featured_word">PREPARA-SE<br />para<br />alta
                                  velocidade</h1>
                                <h5 className="intro_before_featured_word intro-absolute text-uppercase fw-200">
                                  FIBRA ÓPTICA DE<br />qualidade<br /> QUE SE MANTÉM<br /> ESTÁVEL
                                  E
                                  SEGURA
                                </h5>
                              </div>
                              <div className="intro_layer" data-animation="fadeInUp">
                                <p className="text-uppercase intro_after_featured_word">Assine nossos
                                  planos agora mesmo!</p>
                              </div>
                            </div> {/* eof .intro_layers */}
                          </div> {/* eof .intro_layers_wrapper */}
                        </div> {/* eof .col-* */}
                      </div>{/* eof .row */}
                    </div>{/* eof .container */}
                  </li>
                  <li className="ds slide2">
                    <img loading="lazy" src={Slide2} alt="imagem ilustrativa" />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="intro_layers_wrapper">
                            <div className="intro_layers">
                              <div className="intro_layer" data-animation="fadeInRight">
                                <h1 className="slide2 intro_featured_word">
                                  Planos<br />para<br />empresas<br />&amp;residencias</h1>
                                <h5 className="fw-300 text-uppercase intro_before_featured_word intro-absolute">
                                  A velocidade<br />que você precisa
                                </h5>
                              </div>
                              <div className="intro_layer" data-animation="fadeInUp">
                                <p className="text-uppercase intro_after_featured_word">Assine nossos
                                  planos agora mesmo!</p>
                              </div>
                            </div> {/* eof .intro_layers */}
                          </div> {/* eof .intro_layers_wrapper */}
                        </div> {/* eof .col-* */}
                      </div>{/* eof .row */}
                    </div>{/* eof .container */}
                    <div className="ds social-flex">
                      <p className="social-heading">
                        siga-nos
                      </p>
                      <p className="social-icons">
                        <a target="blank" href="https://www.facebook.com/fibraconn/" ><i className="fa fa-facebook color-white" title="facebook" ></i></a>
                      </p>
                    </div>
                  </li>
                  <li className="ds slide3">
                    <img loading="lazy" src={Slide3} alt="imagem ilustrativa" />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="intro_layers_wrapper">
                            <div className="intro_layers">
                              <div className="intro_layer" data-animation="fadeInRight">
                                <h1 className="slide2 intro_featured_word">
                                  Nao<br />Sofra<br />com<br />lentidao</h1>
                                <h5 className="intro-absolute fw-300 text-uppercase intro_before_featured_word">
                                  velocidade<br /> sem limite de dados
                                </h5>
                              </div>
                              <div className="intro_layer" data-animation="fadeInUp">
                                <p className="text-uppercase intro_after_featured_word">Assine nossos
                                  planos agora mesmo!</p>
                              </div>
                            </div> {/* eof .intro_layers */}
                          </div> {/* eof .intro_layers_wrapper */}
                        </div> {/* eof .col-* */}
                      </div>{/* eof .row */}
                    </div>{/* eof .container */}
                  </li>
                </ul>
              </div>
              <div className="ds social-flex">
                <p className="social-heading">
                  siga-nos
                </p>
                <p className="social-icons">
                  <a target="blank" href="https://www.facebook.com/fibraconn/"><i className="fa fa-facebook color-white" title="facebook"></i></a>
                </p>
              </div>
              <div className="arrow">
                <img src={Bottom} alt="imagem ilustrativa" style={{ filter: 'brightness(1.2)' }} />
              </div>
              <div className="animated-arrow">
                <a href="#price">
                  <div className="mouse_scroll">
                    <span><i className="color-main fa fa-angle-down unu" /></span>
                    <span><i className="color-main fa fa-angle-down doi" /></span>
                    <span><i className="color-main fa fa-angle-down trei" /></span>
                  </div>
                </a>
              </div>
            </section>
            <section className="s-py-80 s-py-lg-120 s-py-xl-40 ls ms superbg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2" id="price">
                    <h5 className="special-heading text-center">ASSINE</h5>
                    <h1 className="text-center">um plano!</h1>
                    <p className="text-center big">Selecione o plano que mais combina com você. Aumente, diminua, ou
                      cancele a qualquer momento.</p>
                  </div>
                </div>
                <div className="divider-45 d-none d-lg-block" />
                <div className="row c-gutter-20">
                  <div className="col-lg-4 col-md-6">
                    <div className="pricing-plan-big hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor2">
                        <h3>
                          250 MEGAs
                        </h3>
                      </div>

                      <div className="plan-desc justify-content-center">
                        <h4>Plano Home</h4>
                        <div className="price-icon">
                          <img src={Gauge1} alt="imagem ilustrativa" />
                        </div>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main5">110,00</span>
                        <span className="plan-decimals small-text">/mensal</span>
                      </div>

                      <div class="divider-35 d-block d-lg-block"></div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Hipervelocidade</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Indicado para até 04 dispositivos</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Link em rendundância</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Suporte personalizado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Técnicos locais</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>100% de garantia de banda</h10>
                        </li>
                      </div>
                      <div className="plan-button-medio3">
                        <a href="https://api.whatsapp.com/send?phone=556935352424&text=Quero%20assinar%20o%20plano%20de%20250%20megas" className="btn btn-maincolor"><span>ASSINAR</span></a>
                      </div>

                    </div>
                  </div>
                  <div className="divider-45 d-block d-md-none" />
                  <div className="col-lg-4 col-md-6">
                    <div className="pricing-plan-big hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor2">
                        <h3>
                          350 MEGAs
                        </h3>
                      </div>
                      <div className="plan-desc justify-content-center">
                        <h4>Plano Home</h4>
                        <div className="price-icon">
                          <img src={Gauge3} alt="imagem ilustrativa" />
                        </div>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main5">130,00</span>
                        <span className="plan-decimals small-text">/mensal</span>
                      </div>
                      <div class="divider-35 d-block d-lg-block"></div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Hipervelocidade</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Indicado para até 04 dispositivos</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Link em rendundância</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Suporte personalizado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Técnicos locais</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>100% de garantia de banda</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Watch Brasil</h10>
                        </li>
                      </div>
                      <div className="plan-button-medio">
                        <a href="https://api.whatsapp.com/send?phone=556935352424&text=Quero%20assinar%20o%20plano%20de%20350%20megas" className="btn btn-maincolor"><span>ASSINAR</span></a>
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-4 col-md-6">
                    <div className="pricing-plan-big hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor2">
                        <h3>
                          500 MEGAs
                        </h3>
                      </div>
                      <div className="plan-desc justify-content-center">
                        <h4>Plano Home</h4>
                        <div className="price-icon">
                          <img src={Gauge4} alt="imagem ilustrativa" />
                        </div>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main3">160,00</span>
                        <span className="plan-decimals small-text">/mensal</span>
                      </div>
                      <div class="divider-35 d-block d-lg-block"></div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Hipervelocidade</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Indicado para até 04 dispositivos</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Link em rendundância</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Suporte personalizado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Técnicos locais</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>100% de garantia de banda</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Watch Brasil</h10>
                        </li>
                      </div>

                      <div className="plan-button-medio">
                        <a href="https://api.whatsapp.com/send?phone=556935352424&text=Quero%20assinar%20o%20plano%20de%20500%20megas" className="btn btn-maincolor"><span>ASSINAR</span></a>
                      </div>
                    </div>
                  </div>
                  <div className="divider-45 d-block d-md-none" />
                  <div className="col-lg-4 col-md-6">
                    <div className="pricing-plan-big hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor2">
                        <h3>
                          650 MEGAs
                        </h3>
                      </div>
                      <div className="plan-desc justify-content-center">
                        <h4 className="gold">Plano Gold</h4>
                        <div className="price-icon">
                          <img src={Gauge5} alt="imagem ilustrativa" />
                        </div>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main3">190,00</span>
                        <span className="plan-decimals small-text">/mensal</span>
                      </div>
                      <div class="divider-35 d-block d-lg-block"></div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Hipervelocidade</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Indicado para até 06 dispositivos</h10>
                        </li>
                      </div>

                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Roteador WI-FI - alta performance</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Link em rendundância</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Suporte personalizado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Técnicos locais</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>100% de garantia de banda</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Watch Brasil</h10>
                        </li>
                      </div>

                      <div className="plan-button-medio2">
                        <a href="https://api.whatsapp.com/send?phone=556935352424&text=Quero%20assinar%20o%20plano%20de%20650%20megas" className="btn btn-maincolor"><span>ASSINAR</span></a>
                      </div>
                    </div>
                  </div>
                  <div className="divider-45 d-block d-md-none" />
                  <div className="col-lg-4 col-md-6">
                    <div className="pricing-plan-big hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor2">
                        <h3>
                          800 MEGAs
                        </h3>
                      </div>
                      <div className="plan-desc justify-content-center">
                        <h4 className="premium">Plano Premium</h4>
                        <div className="price-icon">
                          <img src={Gauge6} alt="imagem ilustrativa" />
                        </div>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main3">220,00</span>
                        <span className="plan-decimals small-text">/mensal</span>
                      </div>
                      <div class="divider-35 d-block d-lg-block"></div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Hipervelocidade</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Recomendado para gamer</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Indicado para até 08 dispositivos</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Link em rendundância</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Download ilimitado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Suporte personalizado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Roteador WI-FI - alta performance</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Técnicos locais</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>100% de garantia de banda</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Watch Brasil</h10>
                        </li>
                      </div>


                      <div className="plan-button">
                        <a href="https://api.whatsapp.com/send?phone=556935352424&text=Quero%20assinar%20o%20plano%20de%20800%20megas" className="btn btn-maincolor"><span>ASSINAR</span></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6" >
                    <div className="pricing-plan-big hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor2">
                        <h3>
                          1 GIGA
                        </h3>
                      </div>
                      <div className="plan-desc justify-content-center">
                        <h4 className="black">Plano Black</h4>
                        <div className="price-icon">
                          <img src={Gauge7} alt="imagem ilustrativa" />
                        </div>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main3">280,00</span>
                        <span className="plan-decimals small-text">/mensal</span>
                      </div>
                      <div class="divider-35 d-block d-lg-block"></div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Hipervelocidade</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Recomendado para gamer</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Indicado para até 10 dispositivos</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Link em rendundância</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Download ilimitado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Suporte personalizado</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Roteador WI-FI Premium Grátis</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Técnicos locais</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>100% de garantia de banda</h10>
                        </li>
                      </div>
                      <div className="plan-list justify-content-left">
                        <li>
                          <h10>Watch Brasil</h10>
                        </li>
                      </div>


                      <div className="plan-button">
                        <a href="https://api.whatsapp.com/send?phone=556935352424&text=Quero%20assinar%20o%20plano%20de%201%20giga" className="btn btn-maincolor"><span>ASSINAR</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page1_watch">

                <div className="cat">
                  <div className="fig">

                  </div>

                </div>

              </div>
            </section>

            <Watch />
            <div className="divider-10-negativo" />
            <Mapa />
            <section className="ds s-pt-70 s-pb-130 s-pt-lg-130 s-pb-lg-160 s-pt-xl-130 s-pb-xl-160 background-gradient2 about-section cover-background" id="about">
              <div className="container">
                <div className="d-block d-lg-none divider-5" />
                <div className="row c-gutter-60 align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 order-2 order-md-1 animate" data-animation="fadeInLeftBig">
                    <div className="side-image">
                      <img src={mocaPulando} width={880} alt="imagem ilustrativa" />
                      <div className="d-block d-lg-none divider-10" />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 order-1 order-md-2">
                    <div className="item-content text-center text-md-left text-dark">
                      <h5 className="fw-200 text-uppercase text-dark">Nós somos</h5>
                      <h3 className="text-uppercase text-dark">#1 A melhor opção que você pode escolher!</h3>
                      <h6 className="fw-500 font-italic text-dark">Para começar a usar a FIBRACONN, veja todos nossos planos
                        e entre em contato com nossos operadores!</h6>
                      <p className="big">Desde o início estivemos em busca de melhorias tanto em tecnologia com e
                        qualificação da nossa equipe.
                        Todas as decisões sempre foram tomadas com o objetivo de sermos reconhecidos
                        regionalmente por oferecer serviços de qualidade.
                        Atualmente nossa empresa se encontra em expansão, pois ampliamos nossa área de
                        cobertura e agregamos novos serviços e sempre preocupados
                        com nosso foco principal que é a satisfação dos nossos clientes.
                      </p>
                      <div className="d-flex justify-content-between mt-50">
                        <a className="btn btn-green" href="https://api.whatsapp.com/send?phone=556935352424&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20por%20favor!"><span>Assine agora!</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block divider-35" />
            </section>
            <section className="s-pt-75 s-pt-lg-120 s-pt-xl-155 s-pb-xl-160 ls ms" id="testimonials">
              <div className="container-fluid py-0">
                <div className="row">
                  <div className="col-md-12 px-0">
                    <div className="testimonials-slider owl-carousel" data-autoplay="false" data-loop="false" data-responsive-lg={1} data-responsive-md={1} data-responsive-sm={1} data-nav="false" data-dots="true">
                      <div className="quote-item">
                        <div className="quote-image">
                          <img loading="lazy" src={Missao} alt="imagem ilustrativa" />
                        </div>
                        <div className="quote-inner container py-5">
                          <div className="row">
                            <div className="col-lg-6 offset-lg-6 p-3 quote-column">
                              <p className="quote-content text-white">
                                <strong>Nossa missão</strong>
                              </p>
                              <p className="quote-content text-white">
                                Prover aos clientes acesso a internet com qualidade. Estar em constante
                                aprimoramento e comercializar soluções tecnológicas.
                                Contribuir para o sucesso de pessoas e empresas por meio do nosso trabalho e
                                a partir disso ser referência na área em que atuamos. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="quote-item">
                        <div className="quote-image">
                          <img loading="lazy" src={Visao} alt="imagem ilustrativa" />
                        </div>
                        <div className="quote-inner container py-5">
                          <div className="row">
                            <div className="col-lg-6 offset-lg-6 p-3 quote-column">
                              <p className="quote-content text-white">
                                <strong>Nossa visão</strong>
                              </p>
                              <p className="quote-content text-white">
                                A partir do nosso serviço, buscamos destacar nossa marca no mercado e sermos
                                considerados sinônimo de qualidade pelos nossos clientes.
                                Temos como filosofia principal o respeito e a transparência para com as
                                pessoas, fornecendo produtos e serviços de qualidade, tornando-se um
                                parceiro em relação às questões tecnológicas.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="quote-item">
                        <div className="quote-image">
                          <img loading="lazy" src={Valores} alt="imagem ilustrativa" />
                        </div>
                        <div className="quote-inner container py-5">
                          <div className="row">
                            <div className="col-lg-6 offset-lg-6 p-3 quote-column">
                              <p className="quote-content text-white">
                                <strong>Nossos valores</strong>
                              </p>
                              <p className="quote-content text-white">
                                <ul>
                                  <li>Ética</li>
                                  <li>Comprometimento e Credibilidade</li>
                                  <li>Valorização Humana</li>
                                  <li>Sustentabilidade</li>
                                  <li>Qualidade e agilidade de nossos serviços</li>
                                  <li>Integridade com todos os públicos</li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="ds s-pt-80 s-pb-20 s-pt-lg-130 s-pb-lg-70 s-pt-xl-160 s-pb-xl-100 c-gutter-60 c-mb-50 background-gradient advantage-section cover-background" id="advantage">
              <div className="container">
                <div className="row">
                  <div className="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
                    <div className="icon-box text-center">
                      <div className="image-styled">
                        <img alt="imagem ilustrativa" src={Security} />
                      </div>
                      <h5>
                        Mais segurança
                      </h5>
                      <p>
                        Nossa equipe é preparada para te ensinar a evitar vírus e programas maliciosos na
                        internet
                      </p>
                    </div>
                  </div>{/* .col-* */}
                  <div className="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
                    <div className="icon-box text-center">
                      <div className="image-styled">
                        <img alt="imagem ilustrativa" src={Rocket} />
                      </div>
                      <h5>
                        Alta velocidade
                      </h5>
                      <p>
                        Nossa empresa conta com os melhores equipamentos para que você possa decolar
                        navegando!
                      </p>
                    </div>
                  </div>{/* .col-* */}
                  <div className="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
                    <div className="icon-box text-center">
                      <div className="image-styled">
                        <img alt="imagem ilustrativa" src={Network} />
                      </div>
                      <h5>
                        Várias conexões
                      </h5>
                      <p>
                        Nossa empresa é capaz de montar uma estrutura com equipamentos e tecnologia certa
                        para várias conexões!
                      </p>
                    </div>
                  </div>{/* .col-* */}
                  <div className="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
                    <div className="icon-box text-center">
                      <div className="image-styled">
                        <img alt="imagem ilustrativa" src={Information} />
                      </div>
                      <h5>
                        Fique atento
                      </h5>
                      <p>
                        Nossa equipe está sempre publicando dicas para que você possa ter uma conexão
                        estável e segura!
                      </p>
                    </div>
                  </div>{/* .col-* */}
                  <div className="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
                    <div className="icon-box text-center">
                      <div className="image-styled">
                        <img alt="imagem ilustrativa" src={WhatsApp} />
                      </div>
                      <h5>
                        Fique sem dúvidas
                      </h5>
                      <p>
                        Chega de ficar horas no telefone para tirar suas dúvidas ou relatar um problema,
                        mande uma mensagem para nós!
                      </p>
                    </div>
                  </div>{/* .col-* */}
                  <div className="col-xl-4 col-md-6 animate" data-animation="fadeInUp">
                    <div className="icon-box text-center">
                      <div className="image-styled">
                        <img alt="imagem ilustrativa" src={ParaSempre} />
                      </div>
                      <h5>
                        ilimitada
                      </h5>
                      <p>
                        Navegue sem se preocupar com o consumo! Jogue, baixe filmes e muito mais ILIMITADO.
                      </p>
                    </div>
                  </div>{/* .col-* */}
                  <div className="d-none d-lg-block divider-small" />
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inicio;
