import React from "react";





function Watch() {



  return (
    <section className="s-py-50 s-py-lg-120 s-py-xl-120 ls ms">
     
      <div className="container">
        <div className="divider-10-negativo" />
        <div className="row text-center text-md-left">
          <div className="catalago" />

          <div id="name_Map" className="col-12 col-lg-4 p-0">
            <br />
            <br />
            <h5 className="special-heading text-center text-lg-left">
              Fibraconn +
            </h5>

            <h3 className="text-center text-lg-left text-uppercase">
              Watch Brasil
            </h3>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p>
              <i
                className="color-main4 fs-14"
                aria-hidden="true"
              />
              Levando até você os melhores filmes e séries on demand.
            </p>
            <p>
              + de 8.000 horas de conteúdo.
            </p>
            <p>
              Até 4 dispositivos simultâneos.
            </p>
            <br/>
            <div className="plan-button-medio3">
              <a href="https://www.youtube.com/watch?v=xy2X2Jem-Nc" className="btn btn-maincolor"><span>1º Acesso</span></a>
            </div>
          </div>





        </div>
        <div className="divider-10" />
      </div>
    </section >
  );
}

export default Watch;
